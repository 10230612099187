.modal {
  backdrop-filter: blur(5px);
  border: none;
}

.card a {
  color: white;
}
.card a.active {
  color: rgb(29, 183, 235);
}

.card a:hover {
  color: rgb(29, 183, 235);
  text-decoration: none;
}

.project-pic {
  opacity: 1;
  transition: all 300ms;
  box-shadow: 1px 1px 2px grey;
}

.project-pic:hover {
  opacity: .5;
  cursor: pointer;
}

.modal-button {
  padding: 2px;
  border: none;
  background: none;
  color: white;
  margin-bottom: 5px;
  height: 2rem;
  width: 2rem;
  transition: all 300ms;

}


.modal-button:hover{
  background-color:tomato;

}

