.image {
max-width: 400px; 
border-radius: 5px;
}

.img-div {
  padding-top: 2rem;
  overflow:hidden;
  height: 400px;
  object-fit: cover;
  
}

.thing-two {
  padding: 0;
  border: 1px solid yellow;
}
.about-grid-container {
  padding-top:4rem;

}

.about-grid-container p {
  padding: 0;
}