.profile-pic{
  border-radius: 100%;
  width: 200px;
}

/* BOXES */
.blue-box {
  min-height: 90vh;
  background-color: #171530;
}
.bluer-box {
  min-height: 80vh;
  background-color: #171530;
  /* color: #282828; */

}

.teal-box {
  min-height: 60vh;
  background-color: #2b768d;
  color: #171530;
}

.teal-box a i:hover {
  color: #171530 !important;
}

.hello-text {
  font-family: 'Alata', sans-serif;
  font-size: 52px;

}


.bio-text, .bio-text-2 {

  color: white;
  padding: 1rem;
}


@media only screen and (max-width: 800px) {
  .bio-text {
    width:100%;
    text-align: left;
  }

}
