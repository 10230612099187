
.form-style {
  max-width: 90vh;
  margin: auto;
  padding: 2rem;
}


.neg {
  color: tomato;
}

.pos {
  color: lightgreen;
}