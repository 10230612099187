.toolkit {
  display: flex;
  padding: 1rem;
}

.toolkit-item {
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 300ms;
  border: 2px solid #171530;
  background-color: white;
}

.toolkit-item:hover {
  overflow: visible;
  color: white;
  border: 2px solid white;
  background-color: transparent;
  cursor: crosshair;
}


#toolkit-link  {
  color: white;
  transition: 300ms all;
  width: fit-content;
}

#toolkit-link:hover {
  text-decoration: none;
  color:#171530;
  
}
@media only screen and (max-width: 700px) {

 .toolkit-item {
   font-size: 14px;
  }

  .toolkit-item:hover {

  }
}