a {
  color:white;
  transition: all 300ms;
}

a:hover {
  color:#2b768d;
}

.app {
  min-height: 100vh;
  background-color: #171530;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  color: #BFBFBF;
}

body {
    background-color: #171530;
}

.current {
  border: 2px solid pink;
}


h2 {
  font-size: 42px;
  font-family: 'Poppins', sans-serif;
  color: white;

}

h3 {
  font-size: 36px;
  font-family: 'Poppins', sans-serif;
  color:white;
}

h4,h5,h6 {
  font-family: 'Poppins', sans-serif;

}

a i {
  transition: all 300ms;
}

a i:hover{
  color:#0096c3 !important;
}

.line {
  border: 1px solid white;
  width: 70%;
}

p {
  font-size: 18px;
  font-family: 'Poppins', sans-serif;

}

/* FONTS */

.aguafina-font {
  font-family: 'Aguafina Script', cursive;
}

.alata-font {
  font-family: 'Alata', sans-serif;
}

.poppins-font {
  font-family: 'Poppins', sans-serif;
}

.prompt-font {
  font-family: 'Prompt', sans-serif;
}

.righteous-font {
  font-family: 'Righteous', cursive;
}

/* MAIN COLORS */

.bg-color {
  background-color: #171530;
}

.bg-teal {
  background-color: #2b768d;
}

.light-blue-color {
  background-color: #2b768d;
}

.light-blue-text {
  color: rgb(29, 183, 235);
}

.teal-text {
  color: #2b768d;
}

.grey-font {
  color: #BFBFBF;
}



