nav a {
  color: white;
  transition: all 300ms;
}

nav a:hover {
  color: rgb(29, 183, 235);
  text-decoration: none;
}
nav a.active {
  font-weight: bold;
  color: #2b768d;

}

nav a.active:hover {
  color: rgb(29, 183, 235) !important;
}

#nav-style {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #171530;
  z-index: 555;
}