.logo-container {
  height: 100vh;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  background-color: #171530;
  padding: 20px;
  overflow: hidden;
  /* position: relative; */
}

.header {
  font-size: 120px;
  background-image: url(https://media.giphy.com/media/BDucPOizdZ5AI/giphy.gif);
  background-size: contain;
  color: transparent;
  text-transform: uppercase;
  margin: 10px 0;
  background-clip: text;
  -moz-background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-stroke: .5px white;
  padding: 50px  50px 0px 50px;
}

.sub-header {
  font-size: 42px;
  color:#BFBFBF;
  margin-top: -50px;
}





.logo-area {
  position: absolute;
  z-index: 333;
}

.bg-img {
  filter: brightness(50%);
  min-height: 100vh;
  max-width: 200vw;
  opacity: .25;
  overflow:hidden;
}

.mini-nav {
  margin-top: -10px;
  color: white;

}

 .logo-container a {
  color: #BFBFBF;
  margin-left: 2px;
  margin-right: 2px;
  transition: all 300ms;

}

.logo-container a:hover {
  color: rgb(29, 183, 235);
  text-decoration: none;
}
.logo-container a.active {
  font-weight: bold;
  color: #2b768d;

}


@media only screen and (max-width:1200px) {
  .header {
    font-size:80px;
  }
  .sub-header {
    font-size: 18px;
    margin-top: -30px;

  }
}
@media only screen and (max-width:600px) {
  .header {
    font-size:52px;
  }

  .sub-header {
    font-size: 12px;
    margin-top: -20px;
  }
}
